/** @namespace Pwa/Util/DeliveryNotification/shouldShowDeliveryNotification */
export const shouldShowDeliveryNotification = (singleQtyProducts, currentQtyProducts) =>
    singleQtyProducts?.items[0]?.calculated_delivery_time !== currentQtyProducts?.items[0]?.calculated_delivery_time;

/** @namespace Pwa/Util/DeliveryNotification/qtyAfterAddToCart */
export const qtyAfterAddToCart = (productSku, currentAddedQty, prevCartTotals) => {
    const productInCart = prevCartTotals.items.filter(({ sku }) => sku === productSku);
    const prevQtyInCart = productInCart.length ? productInCart[0].qty : 0;

    return prevQtyInCart + currentAddedQty;
};

import GoogleTagManager from '../component/GoogleTagManager';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;
export const GTM_NAME = 'GOOGLE_TAG_MANAGER';

const BEFORE_ITEMS_TYPE = (originalMember) => {
    const maxPosition = Math.max(...originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION));
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <GoogleTagManager />,
            position: maxPosition + INCREASED_POSITION,
            name: GTM_NAME,
        },
    ];
};

export const config = {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE,
        },
    },
};

export default config;

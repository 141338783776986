export const PERSIST_FORM = 'persistForm';

/** @namespace Pwa/Util/Form/PersistForm/persistForm */
export const persistForm = (formName, formFieldName, formFieldValue) => {
    const persistedObject = sessionStorage.getItem(PERSIST_FORM) || '{}';
    const forms = JSON.parse(persistedObject) || {};

    if (!forms[formName]) {
        forms[formName] = {};
    }

    forms[formName][formFieldName] = formFieldValue;

    sessionStorage.setItem(PERSIST_FORM, JSON.stringify(forms));
};

/** @namespace Pwa/Util/Form/PersistForm/getPersistedForm */
export const getPersistedForm = (formName, returnAsArray) => {
    const persistedObject = sessionStorage.getItem(PERSIST_FORM);
    const forms = persistedObject ? JSON.parse(persistedObject) : null;

    if (forms) {
        const formData = forms[formName];

        if (returnAsArray && returnAsArray.length) {
            returnAsArray.forEach((fieldName) => {
                if (formData && formData[fieldName]) {
                    formData[fieldName] = [formData[fieldName]];
                }
            });
        }

        return formData;
    }

    return null;
};

/** @namespace Pwa/Util/Form/PersistForm/clearPersistedForm */
export const clearPersistedForm = (formName) => {
    const persistedObject = sessionStorage.getItem(PERSIST_FORM);
    const forms = persistedObject ? JSON.parse(persistedObject) : null;

    if (forms && forms[formName]) {
        delete forms[formName];
        sessionStorage.setItem(PERSIST_FORM, JSON.stringify(forms));
    }
};

import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace Pwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const { qty, isSingleProduct, reloadShippingInfo } = this.options;

        if (reloadShippingInfo) {
            return [
                this._getPhysicalProductFragment(),
                qty && Number.isInteger(qty) ? `calculated_delivery_cost(qty: ${qty})` : `calculated_delivery_cost`,
            ];
        }

        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);

        fields.push(
            'delivery_time',
            'alternative_product_design',
            'alternative_hover_image',
            'calculated_delivery_time',
            this._getPriceRangeField(),
            this._getLabelField(),
            this._getRelatedProductsByAttribute()
        );

        if (isSingleProduct) {
            fields.push(
                this._getPhysicalProductFragment(),
                this._getMwCanonicalUrl(),
                this._getLowestPriceField(),
                'is_manage_stock_enabled',
                'calculated_delivery_cost'
            );
        }

        return fields;
    }

    _getLowestPriceField() {
        return new Field('lowest_price').addFieldList([
            'omnibus_text',
            'omnibus_discount_text',
            'value_incl_tax',
            'value_excl_tax',
            'discount',
            'discount_percent',
        ]);
    }

    _getMwCanonicalUrl() {
        return new Field('mw_canonical_url').addFieldList(this._getMwCanonicalFields());
    }

    _getMwCanonicalFields() {
        return ['code', 'url'];
    }

    _getLabelField() {
        return new Field('labels').addFieldList([
            'code',
            'image',
            'text',
            'text_background_color',
            'text_color',
            'label_type',
            'url',
        ]);
    }

    _getRelatedProductsByAttribute() {
        return new Field('products_in_series').addField(
            new Field('row').addFieldList([
                'serie',
                'attr_label',
                'attr_code',
                'attr_type',
                this._getRelatedProductsByAttributeItems(),
            ])
        );
    }

    _getRelatedProductsByAttributeItems() {
        return new Field('items').addFieldList([
            'current_product',
            'is_available',
            'product_id',
            'product_url',
            'thumbnail_url',
            'attr',
            'attr_value',
        ]);
    }

    _getCartProductInterfaceFields() {
        return [
            ...super._getCartProductInterfaceFields(),
            this._getPriceRangeField(),
            'calculated_delivery_time',
            this._getProductSmallField(),
        ];
    }

    _getPhysicalProductFragment() {
        return new Fragment('PhysicalProductInterface').addFieldList(['weight']);
    }

    _getCartProductField() {
        return new Field('product').addFieldList([
            'id',
            'sku',
            'stock_status',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getAttributesField(true, true),
            this._getPriceRangeField(),
        ]);
    }
}

export default new ProductListQuery();

import PropTypes from 'prop-types';

import { IMAGE_LOADED, IMAGE_NOT_FOUND } from 'Component/Image/Image.config';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';
import { isCrawler } from 'Util/Browser/Browser';

/** @namespace Pwa/Component/Image/Component */
export class Image extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        useNativeLazyLoading: PropTypes.bool,
        lazyLoadingType: PropTypes.string,
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        useNativeLazyLoading: false,
        lazyLoadingType: null,
    };

    renderStyledImage() {
        const { alt, src, style, title, useNativeLazyLoading, lazyLoadingType } = this.props;

        return useNativeLazyLoading || isCrawler() ? (
            super.renderStyledImage()
        ) : (
            <ImageLazyLoading
                className="Image-Image"
                src={src || ''}
                alt={alt}
                style={style}
                title={title}
                lazyLoadingType={lazyLoadingType}
            />
        );
    }

    onError() {
        if (isCrawler()) {
            this.setState({ imageStatus: IMAGE_LOADED });
        } else {
            this.setState({ imageStatus: IMAGE_NOT_FOUND });
        }
    }

    renderImageNotFound() {
        return null;
    }
}

export default Image;

import CheckoutSuccess from 'Component/CheckoutSuccess';

import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

const renderDetailsStep = (args, callback, instance) => {
    const {
        orderID,
        isEmailAvailable,
        email,
        event,
        billingAddress: { firstname, lastname },
    } = instance.props;

    return (
        <CheckoutSuccess
            email={email}
            event={event}
            firstName={firstname}
            lastName={lastname}
            isEmailAvailable={isEmailAvailable}
            orderID={orderID}
        />
    );
};

const containerProps = (args, callback, instance) => {
    const { event } = instance.props;

    return {
        ...callback(...args),
        event,
    };
};

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Route/Checkout/Container': {
        'member-function': {
            containerProps,
        },
    },
    'Pwa/Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep,
        },
    },
};

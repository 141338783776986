/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import BlogPostsQuery from '../../query/BlogPosts.query';
import BlogRecentPosts from './BlogRecentPosts.component';

/** @namespace Mirat/Blog/Component/BlogRecentPosts/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

/** @namespace Mirat/Blog/Component/BlogRecentPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace Mirat/Blog/Component/BlogRecentPosts/Container/BlogRecentPostsContainer */
export class BlogRecentPostsContainer extends DataContainer {
    componentDidMount() {
        this._isMounted = true;
        this.requestPosts();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __construct(props) {
        super.__construct(props, 'RecentPosts');
        this.options = {
            recentPosts: {
                pageSize: 3,
                sort: 'DESC',
                getMedia: true,
            },
        };

        this.state = {
            isLoaded: false,
            posts: [],
        };
    }

    /**
     * Prepares the blog posts query with specified options
     * @returns {[[*]]}
     */
    getQuery() {
        const { recentPosts } = this.options;

        return [BlogPostsQuery.getQuery(recentPosts)];
    }

    requestPosts() {
        this.fetchData(
            this.getQuery(),
            ({ posts: { items } }) => this.updatePostList(items, true),
            (err) => {
                this.updatePostList([], false);
                showNotification('error', err);
            }
        );
    }

    /**
     * Maps posts to state and sets isLoaded status
     * @param posts
     * @param isLoaded
     */
    updatePostList(posts, isLoaded) {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            isLoaded,
            posts,
        });
    }

    render() {
        const { posts, isLoaded } = this.state;

        return <BlogRecentPosts posts={posts} isLoaded={isLoaded} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogRecentPostsContainer);

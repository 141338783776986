import { connect } from 'react-redux';

import { PAYMENT_METHOD_STEP } from 'Route/Checkout/Checkout.config';
import {
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';
import { getPersistedForm, persistForm } from 'Util/Form/PersistForm';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            selectedPaymentCode: this.getSelectedByDefaultPaymentMethod(),
        };
    }

    getSelectedByDefaultPaymentMethod() {
        const { paymentMethods } = this.props;
        const persistedPaymentMethod = getPersistedForm(PAYMENT_METHOD_STEP);
        const [{ code } = {}] = paymentMethods;

        if (persistedPaymentMethod && persistedPaymentMethod.paymentMethodCode) {
            const isPersistedPaymentMethodExist = paymentMethods.some(
                (paymentMethod) => paymentMethod.code === persistedPaymentMethod.paymentMethodCode
            );

            if (isPersistedPaymentMethodExist) {
                return persistedPaymentMethod.paymentMethodCode;
            }
        }

        return code;
    }

    selectPaymentMethod({ code }) {
        super.selectPaymentMethod({ code });

        persistForm(PAYMENT_METHOD_STEP, 'paymentMethodCode', code);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);

export const DEFAULT_SLICK_SETTINGS = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};

export const SLIDER_DESKTOP_HEIGHT = 386;
export const SLIDER_TABLET_HEIGHT = 320;
export const SLIDER_MOBILE_HEIGHT = 520;

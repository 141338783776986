import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SliderQuery from 'Query/Slider.query';
import DataContainer from 'Util/Request/DataContainer';

import BannerSlider from './BannerSlider.component';

/** @namespace Pwa/Component/BannerSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    isTablet: state.ConfigReducer.device.isTablet,
});

/** @namespace Pwa/Component/BannerSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/BannerSlider/Container */
export class BannerSliderContainer extends DataContainer {
    static propTypes = {
        position: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        isMobile: PropTypes.bool,
        isTablet: PropTypes.bool,
    };

    state = {
        slider: {},
        isLoading: false,
    };

    componentDidMount() {
        this.getSliderData();
    }

    getSliderData() {
        const { position, code } = this.props;
        this.setState({ isLoading: true });

        this.fetchData(
            [SliderQuery.getSliderQuery(position, code)],
            ({ slider }) => {
                this.setState({
                    slider,
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    isLoading: false,
                });
            }
        );
    }

    containerProps() {
        const { slider, isLoading } = this.state;
        const { isMobile, isTablet } = this.props;

        return {
            slider,
            isMobile,
            isTablet,
            isLoading,
        };
    }

    render() {
        return <BannerSlider {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSliderContainer);

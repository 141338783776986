import { connect } from 'react-redux';

import { DELIVERY_NOTIFICATION_TYPE } from 'Component/DeliveryNotification/DeliveryNotification.config';
import {
    mapDispatchToProps as mapDispatchToPropsSource,
    mapStateToProps as mapStateToPropsSource,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import {
    clearItemDeliveryNotification,
    hideDeliveryNotification,
} from 'Store/DeliveryNotification/DeliveryNotification.action';
import DeliveryNotificationDispatcher from 'Store/DeliveryNotification/DeliveryNotification.dispatcher';
import { debounce } from 'Util/Request';

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

export * from 'SourceComponent/Product/Product.container';

/** @namespace Pwa/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...mapDispatchToPropsSource(dispatch),
    hideDeliveryNotification: (type) => dispatch(hideDeliveryNotification(type)),
    clearItemDeliveryNotification: (type, sku) => dispatch(clearItemDeliveryNotification(type, sku)),
    recalculateDeliveryAndNotify: (sku, quantity, cartTotals, type) =>
        DeliveryNotificationDispatcher.recalculateDeliveryAndNotify(dispatch, sku, quantity, cartTotals, type),
});

/** @namespace Pwa/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...mapStateToPropsSource(state),
    cartTotals: state.CartReducer.cartTotals,
});

/** @namespace Pwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    debouncedRecalculate = debounce(this.recalculate.bind(this), 1000);

    componentWillUnmount() {
        const {
            product: { sku },
        } = this.props;
        const { clearItemDeliveryNotification } = this.props;

        clearItemDeliveryNotification(DELIVERY_NOTIFICATION_TYPE.PDP, sku);
    }

    setQuantity(quantity) {
        super.setQuantity(quantity);

        this.debouncedRecalculate();
    }

    recalculate() {
        const {
            product: { sku },
            cartTotals,
            recalculateDeliveryAndNotify,
        } = this.props;
        const { quantity } = this.state;

        if (quantity) {
            recalculateDeliveryAndNotify(sku, quantity, cartTotals, DELIVERY_NOTIFICATION_TYPE.PDP);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_EXTRA_FEE = 'UPDATE_EXTRA_FEE';

/** @namespace Extrafee/Store/ExtraFee/Action/updateExtraFee */
export const updateExtraFee = (extraFee) => ({
    type: UPDATE_EXTRA_FEE,
    extraFee
});

import { CART_TOTALS, getInitialState, updateShippingPrice } from 'SourceStore/Cart/Cart.reducer';
import { UPDATE_IS_LOADING_CART, UPDATE_SHIPPING_PRICE, UPDATE_TOTALS } from 'Store/Cart/Cart.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

export { CART_TOTALS, getInitialState, updateShippingPrice } from 'SourceStore/Cart/Cart.reducer';

/** @namespace Pwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action) => {
    const { cartData: { items = [], ...rest } = {} } = action;

    const cartTotals = {
        ...rest,
        items: [],
    };

    if (items.length) {
        const normalizedItemsProduct = items.map((item) => {
            // eslint-disable-next-line no-unused-vars
            const { variants, ...normalizedItem } = item;
            // NOTE: deleted itemsku to have receive product with a full variants array
            normalizedItem.product = getIndexedProduct(item.product);

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }

    BrowserDatabase.setItem(cartTotals, CART_TOTALS);

    return { cartTotals, isLoading: false };
};

/** @namespace Pwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_TOTALS:
            return updateCartTotals(action, state);
        case UPDATE_SHIPPING_PRICE:
            return updateShippingPrice(action, state);
        case UPDATE_IS_LOADING_CART:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        default:
            return state;
    }
};

export default CartReducer;

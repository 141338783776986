export const DEFAULT_SLICK_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1000,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                variableWidth: true,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                slidesToShow: 3,
                variableWidth: false,
            },
        },
        {
            breakpoint: 99999,
            settings: {
                arrows: true,
                slidesToShow: 5,
            },
        },
    ],
};

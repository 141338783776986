import BlogCategories from '@mirat/blog/src/component/BlogCategories';
import BlogRecentPosts from '@mirat/blog/src/component/BlogRecentPosts';
import BlogRelatedProducts from '@mirat/blog/src/component/BlogRelatedProducts';
import BlogSearchBar from '@mirat/blog/src/component/BlogSearchBar';
import React from 'react';

import NoMatchHandler from 'Route/NoMatchHandler';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Icons from 'Component/Icons';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';

import { blogContentScroll } from '../util/BlogContent';
import { convertBlogDateFormat } from '../util/BlogDate';

import '../route/PostsDetails/PostsDetails.style.scss';

export default {
    'Mirat/Blog/Route/PostsDetails/Component/PostsDetailsComponent': {
        'member-function': {
            componentDidMount() {
                const sectionTitle = '.BlogPostDetails-SectionTitle';
                blogContentScroll(sectionTitle);
            },
            renderTitle(args, callback, instance) {
                const {
                    post: { title },
                } = instance.props;

                return (
                    <h1 mix={{ block: 'BlogPostDetails', elem: 'Title' }}>
                        <TextPlaceholder length="medium" content={title} />
                    </h1>
                );
            },
            renderTags(args, callback, instance) {
                const {
                    post: { tags },
                    device: { isMobile },
                    isLoaded,
                } = instance.props;

                if (isMobile) {
                    return null;
                }
                if (isLoaded && !tags) {
                    return null;
                }

                return tags.map((tag) => (
                    <div key={tag.title} mix={{ block: 'BlogPostDetails', elem: 'Tag' }}>
                        {tag.title}
                    </div>
                ));
            },
            renderViewCount(args, callback, instance) {
                const {
                    post: { views_count },
                } = instance.props;

                return (
                    <div mix={{ block: 'BlogPostDetails', elem: 'ViewCountBox' }}>
                        <Icons name="eye" width="22" height="22" />
                        <span block="BlogPostDetails" elem="ViewsCount">
                            {views_count}
                        </span>
                    </div>
                );
            },
            renderPublishDate(args, callback, instance) {
                const {
                    post: { publish_time },
                } = instance.props;

                if (!publish_time) {
                    return null;
                }

                return (
                    <div mix={{ block: 'BlogPostDetails', elem: 'Date' }}>
                        <Icons name="calendar" width="22" height="22" />
                        <TextPlaceholder
                            mix={{ block: 'BlogRecentPosts', elem: 'DatePlaceholder' }}
                            content={convertBlogDateFormat(publish_time)}
                        />
                    </div>
                );
            },
            renderContent(args, callback, instance) {
                const {
                    post: { content },
                } = instance.props;

                if (!content) {
                    return (
                        <TextPlaceholder
                            mix={{ block: 'BlogPostDetails', elem: 'ContentPlaceholder' }}
                            length="custom"
                        />
                    );
                }

                return (
                    <div mix={{ block: 'BlogPostDetails', elem: 'Content' }}>
                        <Html content={content} pageType="postDetails" />
                    </div>
                );
            },
            renderMainContent(args, callback, instance) {
                return (
                    <div>
                        {instance.renderTitle()}
                        {instance.renderTags()}
                        <div block="BlogPostDetails" elem="InfoWrapper">
                            {instance.renderPublishDate()}
                            {instance.renderViewCount()}
                        </div>
                        {instance.renderImage()}
                        {instance.renderContent()}
                        {instance.renderMediaGallery()}
                    </div>
                );
            },
            render(args, callback, instance) {
                const { isPostMatch, isLoaded } = instance.props;

                if (!isPostMatch && isLoaded) {
                    return <NoMatch />;
                }

                if (isPostMatch) {
                    return (
                        <NoMatchHandler>
                            <main block="BlogPostDetails">
                                <ContentWrapper wrapperMix={{ block: 'BlogPostDetails', elem: 'Wrapper' }} label="Post">
                                    <div block="BlogPostDetails" elem="ColumnWrapper">
                                        <div block="BlogPostDetails" elem="Description">
                                            {instance.renderPostListingDetails()}
                                            <BlogRelatedProducts />
                                        </div>
                                        <div block="Blog" elem="Sidebar">
                                            <BlogSearchBar />
                                            <BlogCategories />
                                            <BlogRecentPosts />
                                        </div>
                                    </div>
                                </ContentWrapper>
                            </main>
                        </NoMatchHandler>
                    );
                }

                return null;
            },
        },
    },
};

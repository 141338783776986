import {
    CLEAR_ALL_DELIVERY_NOTIFICATION,
    CLEAR_ITEM_DELIVERY_NOTIFICATION,
    HIDE_DELIVERY_NOTIFICATION,
    SET_DELIVERY_COST,
    SET_DELIVERY_TIME,
    SHOW_DELIVERY_NOTIFICATION,
} from './DeliveryNotification.action';

/** @namespace Pwa/Store/DeliveryNotification/Reducer/getInitialState */
export const getInitialState = () => ({
    cart: false,
    minicart: false,
    pdp: false,
    deliveryDate: {},
    deliveryCost: {},
    deliveryTime: {},
    sku: null,
});

/** @namespace Pwa/Store/DeliveryNotification/Reducer/DeliveryNotificationReducer */
export const DeliveryNotificationReducer = (state = getInitialState(), action) => {
    const { notificationType, deliveryDate, deliveryCost, sku, deliveryTime } = action;

    switch (action.type) {
        case SHOW_DELIVERY_NOTIFICATION: {
            return {
                ...state,
                cart: false,
                minicart: false,
                pdp: false,
                [notificationType]: true,
                deliveryDate: {
                    ...state.deliveryDate,
                    [sku]: deliveryDate,
                },
                sku,
            };
        }

        case HIDE_DELIVERY_NOTIFICATION:
            return {
                ...state,
                cart: false,
                minicart: false,
                pdp: false,
            };

        case SET_DELIVERY_COST:
            return {
                ...state,
                deliveryCost: {
                    ...state.deliveryCost,
                    [sku]: deliveryCost,
                },
            };
        case SET_DELIVERY_TIME:
            return {
                ...state,
                deliveryTime: {
                    ...state.deliveryTime,
                    [sku]: deliveryTime,
                },
            };

        case CLEAR_ITEM_DELIVERY_NOTIFICATION: {
            // eslint-disable-next-line no-unused-vars
            const { [sku]: _, ...deliveryDate } = state.deliveryDate;

            return {
                cart: false,
                minicart: false,
                pdp: false,
                deliveryDate: {
                    ...deliveryDate,
                },
            };
        }

        case CLEAR_ALL_DELIVERY_NOTIFICATION: {
            return {
                cart: false,
                minicart: false,
                pdp: false,
                deliveryDate: {},
                deliveryCost: {},
                deliveryTime: {},
                sku: null,
            };
        }

        default:
            return state;
    }
};

export default DeliveryNotificationReducer;

import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Brands/Query */
export class Brands {
    getBrandsQuery(filter, id, pageSize) {
        const query = new Field('mpbrand')
            .addArgument('filter', 'MageplazaBrandsFilterInput', filter)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('storeId', 'Int', id)
            .addField(this._getBrandsItemField());

        return query;
    }

    _getBrandsItemField() {
        return new Field('items').addFieldList([
            'brand_id',
            'image',
            'url_key',
            'value',
            'product_quantity',
            'option_id',
            'description',
            'meta_title',
            'meta_description',
        ]);
    }
}

export default new Brands();

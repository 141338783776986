import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { REDIRECT_AFTER } from 'Component/CheckoutSuccess/CheckoutSuccess.config';
import CheckoutCeneoOpineoQuery from 'Query/CheckoutCeneoOpineo.query';
import { fetchQuery } from 'Util/Request';

import CheckoutSuccess from './CheckoutSuccess.component';

/** @namespace Pwa/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/CheckoutSuccess/Container */
export class CheckoutSuccessContainer extends PureComponent {
    static propTypes = {
        orderID: PropTypes.string.isRequired,
        isEmailAvailable: PropTypes.bool.isRequired,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.getCeneoOpinie();
        this.redirectToPayment();
    }

    componentDidUpdate(prevProps) {
        this.redirectToPayment(prevProps);
    }

    componentWillUnmount() {
        if (document.getElementById('ceneoOpinieScript')) {
            document.getElementById('ceneoOpinieScript').remove();
        }

        if (document.getElementById('ceneoOpinieDataScript')) {
            document.getElementById('ceneoOpinieDataScript').remove();
        }
    }

    loadCeneoScript(ceneoOpinie) {
        const { customer_email, order_increment_id, shop_product_ids, work_days_to_send_questionnaire, script_url } =
            ceneoOpinie;

        const ceneoScript = document.createElement('script');
        const dataScript = document.createElement('script');

        ceneoScript.src = script_url;
        ceneoScript.id = 'ceneoOpinieScript';
        ceneoScript.async = true;

        dataScript.text =
            `ceneo_client_email = "${customer_email}";` +
            `ceneo_order_id = ${order_increment_id};` +
            `ceneo_shop_product_ids = "${shop_product_ids}";` +
            `ceneo_work_days_to_send_questionnaire = ${work_days_to_send_questionnaire};`;
        dataScript.id = 'ceneoOpinieDataScript';
        dataScript.async = true;

        document.body.appendChild(dataScript);
        document.body.appendChild(ceneoScript);
    }

    getCeneoOpinie() {
        const {
            orderID: {
                placeOrder: {
                    order: { order_number },
                },
            },
        } = this.props;

        return fetchQuery(CheckoutCeneoOpineoQuery.getCeneoOpinieQuery(order_number)).then(
            /** @namespace Pwa/Component/CheckoutSuccess/Container/CheckoutSuccessContainer/getCeneoOpinie/fetchQuery/then */
            ({ getCeneoOpinie }) => {
                const { show_script } = getCeneoOpinie;

                if (show_script) {
                    this.loadCeneoScript(getCeneoOpinie);
                }
            },
            /** @namespace Pwa/Component/CheckoutSuccess/Container/CheckoutSuccessContainer/getCeneoOpinie/fetchQuery/then/catch */
            () => {}
        );
    }

    redirectToPayment(prevProps) {
        const {
            orderID: {
                placeOrder: {
                    order: { url },
                },
            },
        } = this.props;

        const { orderID: { placeOrder: { order: { prevUrl } = {} } = {} } = {} } = prevProps || {};

        if (url && url !== prevUrl) {
            setTimeout(() => {
                window.location.href = url;
            }, REDIRECT_AFTER);
        }
    }

    containerProps() {
        const { orderID, isEmailAvailable, email, firstName, lastName, event } = this.props;

        return {
            orderID,
            isEmailAvailable,
            email,
            firstName,
            lastName,
            event,
        };
    }

    render() {
        return <CheckoutSuccess {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessContainer);

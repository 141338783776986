export const SHOW_DELIVERY_NOTIFICATION = 'SHOW_DELIVERY_NOTIFICATION';
export const HIDE_DELIVERY_NOTIFICATION = 'HIDE_DELIVERY_NOTIFICATION';
export const CLEAR_ITEM_DELIVERY_NOTIFICATION = 'CLEAR_ITEM_DELIVERY_NOTIFICATION';
export const CLEAR_ALL_DELIVERY_NOTIFICATION = 'CLEAR_ALL_DELIVERY_NOTIFICATION';
export const SET_DELIVERY_COST = 'SET_DELIVERY_COST';
export const SET_DELIVERY_TIME = 'SET_DELIVERY_TIME';

/** @namespace Pwa/Store/DeliveryNotification/Action/showDeliveryNotification */
export const showDeliveryNotification = (notificationType, deliveryDate, sku) => ({
    type: SHOW_DELIVERY_NOTIFICATION,
    notificationType,
    deliveryDate,
    sku,
});

/** @namespace Pwa/Store/DeliveryNotification/Action/hideDeliveryNotification */
export const hideDeliveryNotification = (notificationType) => ({
    type: HIDE_DELIVERY_NOTIFICATION,
    notificationType,
});

/** @namespace Pwa/Store/DeliveryNotification/Action/clearItemDeliveryNotification */
export const clearItemDeliveryNotification = (notificationType, sku) => ({
    type: CLEAR_ITEM_DELIVERY_NOTIFICATION,
    notificationType,
    sku,
});

/** @namespace Pwa/Store/DeliveryNotification/Action/clearAllDeliveryNotification */
export const clearAllDeliveryNotification = () => ({
    type: CLEAR_ALL_DELIVERY_NOTIFICATION,
});

/** @namespace Pwa/Store/DeliveryNotification/Action/setDeliveryCost */
export const setDeliveryCost = (deliveryCost, sku) => ({
    type: SET_DELIVERY_COST,
    deliveryCost,
    sku,
});

/** @namespace Pwa/Store/DeliveryNotification/Action/setDeliveryTime */
export const setDeliveryTime = (deliveryTime, sku) => ({
    type: SET_DELIVERY_TIME,
    deliveryTime,
    sku,
});

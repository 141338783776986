import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import ImageLazyLoading from 'Component/ImageLazyLoading';
import Link from 'Component/Link';
import { isCrawler } from 'Util/Browser/Browser';

import productPlaceholderSmall from '../../assets/images/productPlaceholderSmallRelated.png';
import { TEXT_TYPE } from './RelatedProductsByAttribute.config';

import './RelatedProductsByAttribute.style';

/** @namespace Pwa/Component/RelatedProductsByAttribute/Component */
export class RelatedProductsByAttribute extends PureComponent {
    static propTypes = {
        productUrlSuffix: PropTypes.string,
        series: PropTypes.array,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        variant: PropTypes.string,
    };

    static defaultProps = {
        productUrlSuffix: '',
        series: [],
        onMouseEnter: () => {},
        onMouseLeave: () => {},
        variant: '',
    };

    renderLabel(label) {
        const { variant } = this.props;

        return label ? (
            <div block="RelatedProductsByAttribute" elem="Label" mods={{ variant }}>
                {label}
            </div>
        ) : null;
    }

    renderProducts(products, type) {
        const { variant, productUrlSuffix, onMouseEnter, onMouseLeave } = this.props;
        const isText = type === TEXT_TYPE;

        if (products && products.length) {
            return (
                <div block="RelatedProductsByAttribute" elem="RelatedProducts" mods={{ variant, isText }}>
                    {products.map(({ product_id, product_url, current_product, thumbnail_url, attr, attr_value }) => {
                        const label = attr && attr !== '' && attr !== 'false' ? attr : attr_value;

                        return (
                            <Link
                                block="RelatedProductsByAttribute"
                                elem="RelatedProduct"
                                mods={{ isCurrent: current_product, isText, isImage: !isText }}
                                key={`relatedproduct-${product_id}`}
                                onMouseEnter={() => onMouseEnter(thumbnail_url)}
                                onMouseLeave={() => onMouseLeave()}
                                to={`${product_url}${productUrlSuffix}`}
                            >
                                {!isText && isCrawler() ? (
                                    <img src={thumbnail_url || productPlaceholderSmall} alt="Name" />
                                ) : null}
                                {!isText && !isCrawler() ? (
                                    <ImageLazyLoading
                                        src={thumbnail_url || productPlaceholderSmall}
                                        alt="Name"
                                        lazyLoadingType="productTileRelated"
                                    />
                                ) : null}
                                {isText ? label : null}
                            </Link>
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    render() {
        const { series } = this.props;

        return series && series.row ? (
            <div block="RelatedProductsByAttribute">
                {series.row.map(({ attr_label, attr_type, items }, index) => (
                    <div block="RelatedProductsByAttribute" elem="Group" key={`group${index}`}>
                        {this.renderLabel(attr_label)}
                        {this.renderProducts(items, attr_type)}
                    </div>
                ))}
            </div>
        ) : null;
    }
}

export default withRouter(RelatedProductsByAttribute);

import Button from 'Component/Button';
import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import { BILLING_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { CheckoutAddressBook as SourceCheckoutAddressBook } from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import { getDefaultAddressLabel } from 'Util/Address';
import { getPersistedForm } from 'Util/Form/PersistForm';

import './CheckoutAddressBook.style';

/** @namespace Pwa/Component/CheckoutAddressBook/Component */
export class CheckoutAddressBook extends SourceCheckoutAddressBook {
    renderOptionalCustomAddress() {
        const { isCustomAddressExpanded } = this.state;

        return (
            <div block="CheckoutAddressBook" elem="CustomAddressWrapper">
                {!isCustomAddressExpanded && (
                    <Button variant="outlineInverse" onClick={this.expandCustomAddress}>
                        {__('Use other address')}
                    </Button>
                )}
                {isCustomAddressExpanded && this.renderCustomAddress()}
            </div>
        );
    }

    renderCustomAddress() {
        const { isBilling, onShippingEstimationFieldsChange, isSubmitted } = this.props;
        const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;
        const persistedAddress = getPersistedForm(formPortalId, ['street']) || {};

        return (
            <CheckoutAddressForm
                onShippingEstimationFieldsChange={onShippingEstimationFieldsChange}
                address={persistedAddress}
                id={formPortalId}
                isSubmitted={isSubmitted}
            />
        );
    }

    renderAddress(address, index) {
        const { onAddressSelect, selectedAddressId, isBilling } = this.props;
        const addressNumber = index + 1;
        const { id, default_billing, default_shipping } = address;
        const postfix =
            (isBilling && default_billing) || (!isBilling && default_shipping) ? getDefaultAddressLabel(address) : '';

        return (
            <CheckoutAddressTable
                onClick={onAddressSelect}
                isSelected={selectedAddressId === id}
                title={__('Address #%s%s', addressNumber, postfix)}
                address={address}
                key={id}
                showAdditionalFields
            />
        );
    }

    render() {
        return <div block="CheckoutAddressBook">{this.renderContent()}</div>;
    }
}

export default CheckoutAddressBook;

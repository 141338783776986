import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import {
    DEFAULT_MAX_PRODUCTS,
    DEFAULT_MIN_PRODUCTS,
    getQuantity,
    MAX_SALE_QTY,
    MIN_SALE_QTY,
    SALABLE_QTY,
} from 'Util/Product/Extract';

export * from 'SourceUtil/Product/Extract';

/** @namespace Pwa/Util/Product/Extract/getProductInStock */
export const getProductInStock = (product, parentProduct = {}) => {
    if (!product) {
        return false;
    }

    const { type_id: type, variants = [], items = [], stock_item: { in_stock: inStock = false } = {} } = product;

    if (type === PRODUCT_TYPE.bundle) {
        const { items = [] } = product;
        const requiredItems = items.filter(({ required }) => required);
        const requiredItemsInStock = requiredItems.filter(({ options }) =>
            options.some(({ product }) => getProductInStock(product))
        );

        return inStock && requiredItemsInStock.length === requiredItems.length;
    }

    if (type === PRODUCT_TYPE.configurable && parentProduct === product) {
        return inStock && !!variants.some((variant) => getProductInStock(variant, product));
    }

    const { type_id: parentTypeId = false } = parentProduct;

    if (parentTypeId === PRODUCT_TYPE.configurable && parentProduct !== product) {
        const { stock_item: { in_stock: parentInStock = false } = {}, stock_status: parentStockStatus } = parentProduct;

        return parentInStock && parentStockStatus !== STOCK_TYPE.OUT_OF_STOCK && getProductInStock(product);
    }

    if (type === PRODUCT_TYPE.grouped) {
        return inStock && !!items.some(({ product }) => getProductInStock(product));
    }

    const { stock_status: stockStatus } = product;

    return stockStatus !== STOCK_TYPE.OUT_OF_STOCK && (inStock || stockStatus === STOCK_TYPE.IN_STOCK);
};

/** @namespace Pwa/Util/Product/Extract/getMinQuantity */
export const getMinQuantity = (product, configIndex = -1) => {
    const { is_manage_stock_enabled } = product;

    if (!is_manage_stock_enabled) {
        return DEFAULT_MIN_PRODUCTS;
    }

    return getQuantity(product, DEFAULT_MIN_PRODUCTS, MIN_SALE_QTY, configIndex);
};

/**
 * Returns maximum purchasable item quantity.
 * @param product
 * @param configIndex
 * @returns {*}
 * @namespace Pwa/Util/Product/Extract/getMaxQuantity */
export const getMaxQuantity = (product, configIndex = -1) => {
    const { is_manage_stock_enabled } = product;

    if (!is_manage_stock_enabled) {
        return DEFAULT_MAX_PRODUCTS;
    }

    const maxQuantity = getQuantity(product, DEFAULT_MAX_PRODUCTS, MAX_SALE_QTY, configIndex);
    const salableQuantity = getQuantity(product, DEFAULT_MAX_PRODUCTS, SALABLE_QTY, configIndex);
    return Math.min(maxQuantity, salableQuantity);
};

import ProductCustomizableOptions from 'Component/ProductCustomizableOptions';
import ProductPrice from 'Component/ProductPrice';
import { Product as SourceProduct } from 'SourceComponent/Product/Product.component';

/** @namespace Pwa/Component/Product/Component */
export class Product extends SourceProduct {
    renderCustomizableOptions() {
        const {
            product: { options },
            updateSelectedValues,
        } = this.props;

        return options ? (
            <ProductCustomizableOptions updateSelectedValues={updateSelectedValues} options={options} />
        ) : null;
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const { type_id: type, price_tiers: priceTiers, variants = [] } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    meta
                    price={productPrice}
                    priceType={type}
                    tierPrices={priceTiers}
                    isPreview={isPreview}
                    mix={{ block: this.className, elem: 'Price' }}
                    isSchemaRequired
                    variantsCount={variants.length}
                />
            </div>
        );
    }
}

export default Product;

import {
    EVENT_GTM_PRODUCT_ADD_TO_CART,
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART,
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

class CartItemChangeQuantityPlugin {
    removeProductAndUpdateCrossSell = (args, callback, instance) => {
        const { event, item } = instance.props;

        event(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
            product: { ...item },
            isCart: true,
        });

        return callback(...args);
    };

    handleChangeQuantity = (args, callback, instance) => {
        const [quantity] = args;
        const {
            item,
            item: { qty },
        } = instance.props;

        this.handleChangeState = {
            newQuantity: quantity,
            oldQuantity: qty,
            item,
        };

        callback(...args);
    };

    changeItemQty = (args, callback, _instance) => {
        const { newQuantity, item, oldQuantity } = this.handleChangeState;
        const [dispatch, _] = args;

        return callback(...args).then((result) => {
            if (oldQuantity < newQuantity) {
                dispatch(
                    event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                        product: { ...item, qty: newQuantity - oldQuantity },
                        isCart: true,
                    })
                );
            } else {
                dispatch(
                    event(EVENT_GTM_PRODUCT_REMOVE_FROM_CART, {
                        product: { ...item, qty: newQuantity - oldQuantity },
                        isCart: true,
                    })
                );
            }

            return result;
        });
    };
}

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

const { handleChangeQuantity, changeItemQty, removeProductAndUpdateCrossSell } = new CartItemChangeQuantityPlugin();

export default {
    'Component/CartItem/Container': {
        'member-function': {
            handleChangeQuantity,
            removeProductAndUpdateCrossSell,
        },
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            changeItemQty,
        },
    },
    'Component/CartItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
};

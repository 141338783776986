import { v4 as uuidv4 } from 'uuid';

export const BC_TYPE = 'miratPWA';

// eslint-disable-next-line import/no-mutable-exports
export let windowGID = null;

/** @namespace Pwa/Util/BroadcastChannel/ReloadPage/subscribeToReloadPageAfterOrder */
export const subscribeToReloadPageAfterOrder = () => {
    const { BroadcastChannel, location } = window;

    if (BroadcastChannel) {
        windowGID = `${Date.now()}.${uuidv4()}`;

        const broadcast = new BroadcastChannel(BC_TYPE);
        broadcast.onmessage = (update) => {
            const {
                data: {
                    payload: { refreshAfterOrder = false, currentWindowGID },
                },
            } = update;

            if (refreshAfterOrder && currentWindowGID !== windowGID) {
                location.reload();
            }
        };
    }
};

/** @namespace Pwa/Util/BroadcastChannel/ReloadPage/emitReloadPageAfterOrder */
export const emitReloadPageAfterOrder = () => {
    const { BroadcastChannel } = window;

    if (BroadcastChannel) {
        const broadcast = new BroadcastChannel(BC_TYPE);

        broadcast.postMessage({ payload: { refreshAfterOrder: true, currentWindowGID: windowGID }, type: BC_TYPE });
        broadcast.close();
    }
};

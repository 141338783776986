import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps } from 'Component/MyAccountAddressForm/MyAccountAddressForm.container';
import { CheckoutAddressFormContainer as SourceCheckoutAddressFormContainer } from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';
import { persistForm } from 'Util/Form/PersistForm';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/CheckoutAddressForm/Container */
export class CheckoutAddressFormContainer extends SourceCheckoutAddressFormContainer {
    onCountryChange(field, e) {
        const { name, value } = e;
        const { id } = this.props;

        persistForm(id, name, value);

        super.onCountryChange(field, e);
    }

    containerProps() {
        return {
            ...super.containerProps(),
            ...this.props,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressFormContainer);

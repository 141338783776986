import { EVENT_GTM_PRODUCT_CLICK } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const handleItemClick = (args, callback, instance) => {
    const { event, position, product } = instance.props;
    const { pathname } = window.location;
    const search = true;

    const productToPass = {
        ...product,
        position,
        pathname,
        search,
    };

    event(EVENT_GTM_PRODUCT_CLICK, productToPass);
    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

export default {
    'Component/SearchItem/Container': {
        'member-function': {
            handleItemClick,
        },
    },
    'Component/SearchItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
};

import { Field } from 'Util/Query';

/** @namespace Pwa/Query/PromoBanners/Query */
export class PromoBannersQuery {
    setPromoBannerClickMutation(id, referrer) {
        const mutation = new Field('mstBannerTrackClick')
            .addArgument('bannerId', 'Int!', id)
            .addArgument('referrer', 'String!', referrer);

        return mutation;
    }

    setPromoBannerImpressionMutation(id, referrer) {
        const mutation = new Field('mstBannerTrackImpression')
            .addArgument('bannerId', 'Int!', id)
            .addArgument('referrer', 'String!', referrer);

        return mutation;
    }

    getPromoBanner(position, context = {}) {
        const mutation = new Field('mstBannerPlaceholders')
            .addArgument('position', 'String!', position)
            .addArgument('context', 'mstBannerContextInput', context)
            .addFieldList([this._getBannerFields(), 'identifier']);

        return mutation;
    }

    _getBannerFields() {
        return new Field('banners').addFieldList(['banner_id', 'content']);
    }
}

export default new PromoBannersQuery();

import CheckoutCeneoOpineo from 'Component/CheckoutCeneoOpineo';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Icons from 'Component/Icons';
import Link from 'Component/Link';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { CheckoutBilling as SourceCheckoutBilling } from 'SourceComponent/CheckoutBilling/CheckoutBilling.component';
import { isSignedIn } from 'Util/Auth';
import { scrollToErrorByClassName } from 'Util/Form/Form';

import { SHIPPING_URL } from './CheckoutBilling.config';

/** @namespace Pwa/Component/CheckoutBilling/Component */
export class CheckoutBillingComponent extends SourceCheckoutBilling {
    state = {
        ...this.state,
        selectedAgreementsList: [],
        requiredAgreementsList: [],
    };

    componentDidMount() {
        this.setRequiredAgreements();
    }

    setRequiredAgreements = () => {
        const { termsAndConditions } = this.props;
        const requiredAgreementsList = [];
        termsAndConditions.map(({ agreement_id }) => requiredAgreementsList.push(agreement_id.toString()));

        this.setState({ requiredAgreementsList });
    };

    renderOrderComment() {
        return (
            <div block="CheckoutBilling" elem="Comment">
                <h2 block="CheckoutBilling" elem="Heading">
                    {__('Order Comment information')}
                </h2>
                <Field
                    type={FIELD_TYPE.textarea}
                    attr={{
                        id: 'customer_comment',
                        name: 'customer_comment',
                        placeholder: __('Enter comment'),
                        'aria-label': __('Order Comment'),
                        rows: 5,
                    }}
                />
            </div>
        );
    }

    renderActions() {
        const { isOrderButtonVisible, isOrderButtonEnabled, isTermsAndConditionsAccepted } = this.state;

        const { termsAreEnabled, setShowTermsErrors } = this.props;

        if (!isOrderButtonVisible) {
            return null;
        }

        return (
            <div block="Checkout" elem="ButtonWrapper">
                <Link to={SHIPPING_URL}>
                    <Icons name="next" height="14" />
                    {__('Back')}
                </Link>
                {termsAreEnabled && !isTermsAndConditionsAccepted ? (
                    <button
                        type="button"
                        block="Button"
                        disabled={!isOrderButtonEnabled}
                        onClick={() => {
                            setShowTermsErrors();
                            scrollToErrorByClassName();
                        }}
                        mix={{ block: 'CheckoutBilling', elem: 'Button' }}
                    >
                        {__('Submit order')}
                    </button>
                ) : (
                    <button
                        type="submit"
                        block="Button"
                        disabled={!isOrderButtonEnabled}
                        onClick={scrollToErrorByClassName}
                        mix={{ block: 'CheckoutBilling', elem: 'Button' }}
                    >
                        {__('Submit order')}
                    </button>
                )}
            </div>
        );
    }

    checkTermsAndConditions = () => {
        const { requiredAgreementsList, selectedAgreementsList } = this.state;
        const requiredAgreementsSelected = requiredAgreementsList.every(
            (elem) => selectedAgreementsList.indexOf(elem) > -1
        );

        this.setState({ isTermsAndConditionsAccepted: requiredAgreementsSelected });
    };

    updateAgreementsList = (el) => {
        const isChecked = el.target.checked;
        const agreementId = el.target.value;

        if (isChecked) {
            const agreements = this.state.selectedAgreementsList.concat(agreementId);
            this.setState(
                {
                    selectedAgreementsList: agreements,
                },
                () => this.checkTermsAndConditions()
            );
        } else {
            const agreements = this.state.selectedAgreementsList.filter((val) => val !== agreementId);
            this.setState(
                {
                    selectedAgreementsList: agreements,
                },
                () => this.checkTermsAndConditions()
            );
        }

        return null;
    };

    renderTermsItem = (item, i) => {
        const { checkbox_text, content, is_html, is_required = true, agreement_id } = item;
        const { selectedAgreementsList } = this.state;
        const { showTermsErrors } = this.props;

        const isChecked = selectedAgreementsList.includes(agreement_id.toString());

        return (
            <div block="CheckoutBilling" elem="TACItem">
                <div block="CheckoutBilling" elem="TACItemOption" key={i}>
                    <Field
                        type={FIELD_TYPE.checkbox}
                        attr={{
                            id: `termsAndConditions-${agreement_id}`,
                            name: `termsAndConditions-${agreement_id}`,
                            value: agreement_id,
                            required: { is_required },
                        }}
                        checked={isChecked}
                        events={{
                            onChange: this.updateAgreementsList,
                        }}
                        mix={{ block: 'CheckoutBilling', elem: 'TermsAndConditions-Checkbox' }}
                    />
                    <label
                        block="CheckoutBilling"
                        elem="TACLabel"
                        htmlFor={`termsAndConditions-${agreement_id}`}
                        mods={{ required: is_required }}
                    >
                        {is_html ? <div dangerouslySetInnerHTML={{ __html: content }} /> : checkbox_text}
                    </label>
                </div>
                {is_required && !isChecked && showTermsErrors ? (
                    <div block="Field" elem="ErrorMessage">
                        {__('This field is required')}
                    </div>
                ) : null}
            </div>
        );
    };

    renderTerms() {
        const { termsAreEnabled, termsAndConditions } = this.props;

        if (!termsAreEnabled) {
            return null;
        }

        return (
            <div block="CheckoutBilling" elem="TermsAndConditions" id="CheckoutBilling-TermsAndConditions">
                {termsAndConditions.map(this.renderTermsItem)}
            </div>
        );
    }

    renderNewsletter() {
        const { newsletter_active, newsletter_guest, setNewsletterAccepted, newsletterSubscription } = this.props;

        if (!newsletter_active || (!isSignedIn() && !newsletter_guest)) {
            return null;
        }

        return (
            <div block="CheckoutBilling" elem="Newsletter">
                <Field
                    type={FIELD_TYPE.checkbox}
                    attr={{
                        id: 'newsletter',
                        name: 'newsletter',
                        value: 'newsletter',
                        checked: newsletterSubscription,
                    }}
                    events={{
                        onChange: setNewsletterAccepted,
                    }}
                    mix={{ block: 'CheckoutBilling', elem: 'TermsAndConditions-Checkbox' }}
                />
                <label block="CheckoutBilling" elem="newsletterLabel" htmlFor="newsletter">
                    {__('Subscribe to newsletter')}
                </label>
            </div>
        );
    }

    renderTermsAndConditions() {
        return (
            <div block="CheckoutBilling" elem="TermsAndConditions">
                {this.renderTerms()}
                <CheckoutCeneoOpineo />
                {this.renderNewsletter()}
            </div>
        );
    }

    renderSameAsShippingCheckbox() {
        const {
            isSameAsShipping,
            onSameAsShippingChange,
            totals: { is_virtual },
            selectedShippingMethod,
        } = this.props;

        if (is_virtual) {
            return null;
        }

        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    id: 'sameAsShippingAddress',
                    name: 'sameAsShippingAddress',
                    value: 'sameAsShippingAddress',
                    checked: !isSameAsShipping && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE,
                }}
                events={{
                    onChange: onSameAsShippingChange,
                }}
                mix={{ block: 'CheckoutBilling', elem: 'Checkbox' }}
                label={__('I want to receive an invoice for other address than for delivery')}
                onChange={onSameAsShippingChange}
                isDisabled={selectedShippingMethod === STORE_IN_PICK_UP_METHOD_CODE}
            />
        );
    }

    renderDiscount() {
        const { renderDiscountCode, isMobile, isTablet } = this.props;

        return isMobile || isTablet ? renderDiscountCode() : null;
    }

    render() {
        const { onBillingSuccess, onBillingError, onFormChange } = this.props;

        return (
            <Form
                attr={{
                    id: BILLING_STEP,
                }}
                mix={{ block: 'CheckoutBilling' }}
                onError={onBillingError}
                onSubmit={onBillingSuccess}
                events={{
                    onChange: onFormChange,
                }}
            >
                {this.renderAddresses()}
                {this.renderPayments()}
                {this.renderDiscount()}
                {this.renderTermsAndConditions()}
                {this.renderOrderComment()}
                {this.renderActions()}
                {this.renderPopup()}
            </Form>
        );
    }
}

export default CheckoutBillingComponent;

import { GoogleTagManagerReducer } from '../store/GoogleTagManager/GoogleTagManager.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    GoogleTagManagerReducer,
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers,
    },
};

export default config;

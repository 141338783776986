Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/mirat/@mirat-advox/extrafee/src/plugin/CheckoutBillingComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/extrafee/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/mirat/@mirat-advox/extrafee/src/plugin/CheckoutComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/extrafee/src/plugin/CheckoutContainer.plugin.js'),
require('./../packages/mirat/@mirat-advox/extrafee/src/plugin/CheckoutMapDispatchToProps.plugin.js'),
require('./../packages/mirat/@mirat-advox/extrafee/src/plugin/CheckoutPaymentsContainer.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogCategoriesComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogPostCardComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogPostQuery.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogPostsQuery.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogRecentPostsComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogRelatedProductsComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogSearchBarComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogSearchBarContainer.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/BlogSearchOverlayComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/PostsDetailsComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/PostsDetailsContainer.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/PostsListingComponent.plugin.js'),
require('./../packages/mirat/@mirat-advox/blog/src/plugin/PostsListingContainer.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/CartItemChangeQuantity.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/CheckoutPurchasePrepare.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/CheckoutShippingContainer.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/CheckoutSuccessComponent.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/HandleCustomerDataOnInit.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/MyAccountQuery.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/NoMatch.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/ProductClick.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/ProductList.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/ProductListPageComponent.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/ProductListQuery.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/ProductPageContainer.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/RouterComponentPlugin.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/RouterContainerMDTP.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/SearchFieldComponent.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/SearchItemContainer.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/SearchOverlayComponent.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/StoreReducer.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/WishlistItemComponent.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/addToCart.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/myAccountLoginRegister.plugin.js'),
require('./../packages/mirat/@mirat/gtm-new/src/plugin/wishlistItemAddToCart.plugin.js'),
require('./../packages/mirat/@mirat/extrafee/src/plugin/CartPageComponent.plugin.js'),
require('./../packages/mirat/@mirat/extrafee/src/plugin/CheckoutOrderSummaryPriceLineComponent.plugin.js'),
require('./../packages/mirat/@mirat/extrafee/src/plugin/StoreReducer.plugin.js'),
require('./../packages/mirat/@mirat/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/mirat/@mirat/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/mirat/@mirat/blog/src/plugin/Image.plugin.js'),
require('./../packages/mirat/@mirat/blog/src/plugin/Router.plugin.js'),
require('./../packages/mirat/@mirat/blog/src/plugin/StoreIndex.plugin.js')]);
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { render } from 'react-dom';

import App from 'Component/App';
import { subscribeToReloadPageAfterOrder } from 'Util/BroadcastChannel';

import 'Util/Polyfill';
import 'intersection-observer/intersection-observer';
import 'Style/main';

subscribeToReloadPageAfterOrder();

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));

import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore';
import DeliveryNotificationReducer from 'Store/DeliveryNotification/DeliveryNotification.reducer';

/** @namespace Pwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    DeliveryNotificationReducer,
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => {
        // eslint-disable-next-line no-param-reassign
        store.asyncReducers[name] = reducer;
        store.injectReducer(name, reducer);
    });

    return store;
}

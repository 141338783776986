import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT,
} from '@scandipwa/scandipwa/src/route/UrlRewrites/UrlRewrites.config';

import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewrites as SourceUrlRewrites,
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

export { ProductPage, CategoryPage, CmsPage, NoMatch };

/** @namespace Pwa/Route/UrlRewrites/Component */
export class UrlRewrites extends SourceUrlRewrites {
    renderProductPage() {
        const { props } = this.props;
        const { history, location, match, productSKU, id } = props;

        if (!productSKU) {
            return this.renderDefaultPage();
        }

        return (
            <ProductPage
                history={history}
                location={location}
                match={match}
                productSKU={productSKU}
                productID={id}
                key={id}
            />
        );
    }

    renderCmsPage() {
        const { props } = this.props;
        const { history, location, match, pageIds } = props;

        return <CmsPage history={history} location={location} match={match} pageIds={pageIds} />;
    }

    renderCategoryPage() {
        const { props } = this.props;
        const { history, location, match, categoryIds } = props;

        return <CategoryPage history={history} location={location} match={match} categoryIds={categoryIds} />;
    }

    renderNoMatch() {
        const { props } = this.props;
        const { history, location, match } = props;

        return <NoMatch history={history} location={location} match={match} />;
    }

    renderDefaultPage() {
        return <main />;
    }

    renderContent() {
        const { type } = this.props;

        switch (type) {
            case TYPE_PRODUCT:
                return this.renderProductPage();
            case TYPE_CMS_PAGE:
                return this.renderCmsPage();
            case TYPE_CATEGORY:
                return this.renderCategoryPage();
            case TYPE_NOTFOUND:
                return this.renderNoMatch();
            default:
                return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;

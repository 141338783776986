export * from 'SourceUtil/Form/Form.js';

/** @namespace Pwa/Util/Form/scrollToErrorByClassName */
export const scrollToErrorByClassName = () => {
    const fieldHasErrorClass = '.Field_hasError';

    setTimeout(() => {
        const array = document.querySelectorAll(fieldHasErrorClass);

        if (array && array[0]) {
            array[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, 100);
};

export default { scrollToErrorByClassName };

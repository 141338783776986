/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const _getStoreConfigFields = (args, callback, instance) => [...callback.apply(instance, args), 'locale'];

export const config = {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields,
        },
    },
};

export default config;

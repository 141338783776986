import { Field, Fragment } from 'Util/Query';

/** @namespace Pwa/Query/DeliveryNotification/Query */
export class DeliveryNotificationQuery {
    getDeliveryNotificationQuery(sku, qty) {
        const query = new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput ', { sku: { eq: sku } })
            .addField(this.getDeliveryNotificationProductsItemsField(qty));

        return query;
    }

    getDeliveryNotificationProductsItemsField(qty) {
        const fields = [
            `calculated_delivery_time(qty: ${qty})`,
            `calculated_delivery_cost(qty: ${qty})`,
            new Fragment('PhysicalProductInterface').addFieldList(['weight']),
        ];

        return new Field('items').addFieldList(fields);
    }
}

export default new DeliveryNotificationQuery();

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributeDropdown } from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

/** @namespace Pwa/Component/ProductConfigurableAttributeDropdown/Component */
export class ProductConfigurableAttributeDropdown extends SourceProductConfigurableAttributeDropdown {
    render() {
        const { selectOptions, selectValue, selectName, selectLabel, onChange } = this.props;

        return (
            <Field
                type={FIELD_TYPE.select}
                attr={{
                    id: selectName,
                    name: selectName,
                    defaultValue: selectValue,
                    selectPlaceholder: __('Choose %s...', selectLabel.toLowerCase()),
                }}
                events={{
                    onChange,
                }}
                mix={{ block: 'ProductConfigurableAttributeDropdown' }}
                options={selectOptions}
                variant="teriary"
                changeValueOnDoubleClick
            />
        );
    }
}

export default ProductConfigurableAttributeDropdown;

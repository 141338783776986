import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SCROLL_TO_TERMS_OFFSET } from 'Component/CheckoutBilling/CheckoutBilling.config';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { BILLING_STEP, PAYMENT_METHOD_STEP } from 'Route/Checkout/Checkout.config';
import {
    CheckoutBillingContainer as SourceCheckoutBillingContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/CheckoutBilling/CheckoutBilling.container';
import { getPersistedForm, persistForm } from 'Util/Form/PersistForm';
import transformToNameValuePair from 'Util/Form/Transform';

export { mapDispatchToProps };

/** @namespace Pwa/Component/CheckoutBilling/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    newsletter_active: state.ConfigReducer.newsletter_general_active,
    newsletter_guest: state.ConfigReducer.newsletter_subscription_allow_guest_subscribe,
    isMobile: state.ConfigReducer.device.isMobile,
    isTablet: state.ConfigReducer.device.isTablet,
});

/** @namespace Pwa/Component/CheckoutBilling/Container */
export class CheckoutBillingContainer extends SourceCheckoutBillingContainer {
    static propTypes = {
        ...SourceCheckoutBillingContainer.propTypes,
        newShippingStreet: PropTypes.PropTypes.string.isRequired,
        renderDiscountCode: PropTypes.func,
    };

    static defaultProps = {
        ...SourceCheckoutBillingContainer.defaultProps,
    };

    containerFunctions = {
        ...this.containerFunctions,
        setShowTermsErrors: this.setShowTermsErrors.bind(this),
        onFormChange: this.onFormChange.bind(this),
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            isSameAsShipping: true,
            selectedCustomerAddressId: 0,
            prevPaymentMethods: this.getDefaultPaymentMethod(),
            paymentMethod: this.getDefaultPaymentMethod(),
            showTermsErrors: false,
        };
    }

    getDefaultPaymentMethod() {
        const { paymentMethods } = this.props;
        const persistedPaymentMethod = getPersistedForm(PAYMENT_METHOD_STEP);
        const [{ code } = {}] = paymentMethods;

        if (persistedPaymentMethod && persistedPaymentMethod.paymentMethodCode) {
            const isPersistedPaymentMethodExist = paymentMethods.some(
                (paymentMethod) => paymentMethod.code === persistedPaymentMethod.paymentMethodCode
            );

            if (isPersistedPaymentMethodExist) {
                return persistedPaymentMethod.paymentMethodCode;
            }
        }

        return code;
    }

    scrollToTerms() {
        const terms = document.getElementById('CheckoutBilling-TermsAndConditions');
        const { top } = terms.getBoundingClientRect();

        window.scrollTo({ top: top + window.scrollY - SCROLL_TO_TERMS_OFFSET, behavior: 'smooth' });
    }

    setShowTermsErrors() {
        this.setState({ showTermsErrors: true });

        this.scrollToTerms();
    }

    onBillingSuccess(form, fields, asyncData) {
        const { savePaymentInformation } = this.props;
        const { isSameAsShipping } = this.state;

        const extractedFields = transformToNameValuePair(fields);
        const address = this._getAddress(extractedFields);
        const paymentMethod = this._getPaymentData(extractedFields, asyncData);
        const { customer_comment } = extractedFields;

        savePaymentInformation(
            {
                billing_address: address,
                paymentMethod,
                same_as_shipping: isSameAsShipping,
            },
            customer_comment
        );
    }

    containerProps() {
        const {
            newsletter_active,
            newsletter_guest,
            newsletterSubscription,
            setNewsletterAccepted,
            renderDiscountCode,
            isMobile,
            isTablet,
        } = this.props;

        const { showTermsErrors } = this.state;

        return {
            ...super.containerProps(),
            newsletter_active,
            newsletter_guest,
            newsletterSubscription,
            setNewsletterAccepted,
            renderDiscountCode,
            isMobile,
            isTablet,
            showTermsErrors,
        };
    }

    isSameShippingAddress({ default_billing, default_shipping }) {
        const {
            totals: { is_virtual },
            selectedShippingMethod,
            newShippingId,
            newShippingStreet,
        } = this.props;

        if (is_virtual) {
            return false;
        }

        const firstRule = !newShippingId && !newShippingStreet && default_billing === default_shipping;
        const secondRule = default_billing && parseInt(default_billing, 10) === newShippingId;

        return (firstRule || secondRule || !default_billing) && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE;
    }

    onFormChange(event) {
        const { target: { name, value } = {} } = event || {};

        persistForm(BILLING_STEP, name, value);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBillingContainer);

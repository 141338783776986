import { EVENT_GTM_PRODUCT_ADD_TO_CART } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

export const addProductToCart = async (args, callback, instance) => {
    const {
        product,
        product: { type_id, variants },
        quantity,
        configurableVariantIndex,
        groupedProductQuantity,
        event,
    } = instance.props;

    callback(...args);

    if (type_id === 'grouped') {
        event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: {
                ...product,
                quantities: groupedProductQuantity,
            },
            isGrouped: true,
        });
    } else {
        const productToAdd = variants ? { ...product, configurableVariantIndex } : product;

        event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: productToAdd,
            quantity,
            configurableVariantIndex,
        });
    }
};
export default {
    'Component/AddToCart/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Component/AddToCart/Container': {
        'member-function': {
            addProductToCart,
        },
    },
};

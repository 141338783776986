const _getCustomerFields = (args, callback) => [
    ...callback(...args),
    'userExistingCustomer',
    'userLifetimeValue',
    'userLifetimeOrders',
];

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields,
        },
    },
};

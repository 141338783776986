import { DELIVERY_NOTIFICATION_TYPE } from 'Component/DeliveryNotification/DeliveryNotification.config';
import DeliveryNotificationQuery from 'Query/DeliveryNotification.query';
import {
    setDeliveryCost,
    setDeliveryTime,
    showDeliveryNotification,
} from 'Store/DeliveryNotification/DeliveryNotification.action';
import { qtyAfterAddToCart, shouldShowDeliveryNotification } from 'Util/DeliveryNotification';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

/** @namespace Pwa/Store/DeliveryNotification/Dispatcher */
export class DeliveryNotificationDispatcher {
    async recalculateDeliveryAndNotify(dispatch, sku, quantity, cartTotals, type) {
        const qty = DELIVERY_NOTIFICATION_TYPE.PDP === type ? qtyAfterAddToCart(sku, quantity, cartTotals) : quantity;

        const { products: singleQtyProducts } = await fetchQuery(
            DeliveryNotificationQuery.getDeliveryNotificationQuery(sku, 1)
        );

        const { products: currentQtyProducts } = await fetchQuery(
            DeliveryNotificationQuery.getDeliveryNotificationQuery(sku, qty)
        );
        const { DeliveryNotificationReducer } = getStore().getState();

        if (
            DELIVERY_NOTIFICATION_TYPE.CART === type ||
            (DELIVERY_NOTIFICATION_TYPE.CART !== type &&
                !(quantity === 1 && !DeliveryNotificationReducer.deliveryDate[sku]))
        ) {
            if (DELIVERY_NOTIFICATION_TYPE.PDP === type) {
                dispatch(setDeliveryTime(currentQtyProducts?.items[0]?.calculated_delivery_time, sku));
            }

            if (shouldShowDeliveryNotification(singleQtyProducts, currentQtyProducts)) {
                dispatch(showDeliveryNotification(type, currentQtyProducts?.items[0]?.calculated_delivery_time, sku));
            }
        }

        dispatch(setDeliveryCost(currentQtyProducts?.items[0]?.calculated_delivery_cost, sku));
    }
}

export default new DeliveryNotificationDispatcher();
